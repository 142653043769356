import * as Sentry from '@sentry/astro';

Sentry.init({
  dsn: 'http://7cb43e204757ffb6a45f8bbf418a7323@74.220.18.112:9000/4',

  integrations: [Sentry.replayIntegration()],
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
